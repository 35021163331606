@import '~antd/dist/antd.less';
@import './styles/themes.less';
@primary: #FF4E00;
@secondary: #2C363F;
@foreground: #EBECEC;
@border: #efeff4;
@defaultHover: #2C363F;
@textHighEmphasis: #2C363F;
@textMediumEmphasis: #2C363F;
@yellowWarn: #faad14;
@redError: #ff4d4f;
@greenSuccess: #5ab864;
@default: #334659;

@import url('https://fonts.googleapis.com/css2?family=Onest:wght@300;400;500;600;800&display=swap');

// @font-face {
//   font-family: 'Mulish';
//   font-weight: 300;
//   src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Mulish';
//   font-weight: 400;
//   src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 500;
//   src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Mulish';
//   font-weight: 600;
//   src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Mulish';
//   font-weight: 800;
//   src: url('./assets/Fonts/Mulish-VariableFont_wght.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-style: light;
//   font-weight: 300;
//   src: url('./assets/Fonts/Roboto-Light.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-weight: 400;
//   src: url('./assets/Fonts/Roboto-Medium.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }
// @font-face {
//   font-family: 'Roboto';
//   font-weight: 500;
//   src: url('./assets/Fonts/Roboto-Regular.ttf') format('truetype');
//   font-display: swap;
//   max-age: 31536000;
// }

.__react_component_tooltip {
  background-color: #222 !important;
  span {
    color: white !important;
  }
}

#root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.ant-modal-content {
  border-radius: 8px;
  .ant-modal-body {
    padding: 0 30px 30px 30px;
    color: #2C363F;
  }
  .ant-modal-header {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
}

* {
  font-family: Onest, sans-serif;
  // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  // -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

b,
strong {
  color: @textHighEmphasis;
}

p,
span,
label {
  color: @textMediumEmphasis;
}

a {
  color: @primary;
}

a:hover {
  color: @defaultHover;
}

body {
  background: #ffffff;
}

label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  // 
  text-align: left;
  color: @textMediumEmphasis;
}

button {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  // letter-spacing: 1.25px;
  text-align: center;
  cursor: pointer;
}

h1 {
  width: 100%;
  font-family: 'Onest', sans-serif;
  font-size: 35px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  // letter-spacing: normal;
  text-align: left;
  color: @textHighEmphasis;

  @media (max-width: 780px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
}

h2 {
  font-family: Onest;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  // letter-spacing: normal;
  color: @textHighEmphasis;
  @media (max-width: 480px) {
    font-family: Onest;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    // letter-spacing: normal;
  }
}

h3 {
  font-family: Onest;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  // letter-spacing: normal;
  color: @textHighEmphasis;
}

h4 {
  font-family: 'Onest', sans-serif;
  font-size: 20px;
  font-weight: 300;
  // letter-spacing: normal;
  text-align: left;
  color: @textMediumEmphasis;
  width: 100%;
  @media (max-width: 780px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
}

h5 {
  font-family: Onest;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  // 
  color: @textHighEmphasis;
}

span.anticon.anticon-rotate-left.ant-image-preview-operations-icon,
span.anticon.anticon-zoom-in.ant-image-preview-operations-icon,
span.anticon.anticon-rotate-right.ant-image-preview-operations-icon,
span.anticon.anticon-zoom-out.ant-image-preview-operations-icon {
  display: none;
}

span.anticon.anticon-close.ant-image-preview-operations-icon {
  background: @primary;
  border-radius: 18px;
  padding: 7px;
  font-size: 18px;
}
