@primary-color: #FF4E00;
@secondary-color: #2C363F;
@checkbox-check-color: rgba(255, 255, 255, 0);

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid rgba(255, 255, 255, 1);
  border-top: 0;
  border-left: 0;
}

.ant-btn {
  border-color: @primary-color;
}

.ant-select-lg {
  font-size: 14px;
}

.ant-notification-notice {
  border-radius: 20px;
}